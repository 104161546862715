// import 'zone.js'; // for angular subapp
import { registerMicroApps, runAfterFirstMounted, setDefaultMountApp, start, initGlobalState } from 'qiankun';
import './index.less';
/**
 * 主应用 **可以使用任意技术栈**
 * 以下分别是 React 和 Vue 的示例，可切换尝试
 */
// import render from './render/ReactRender';
import render from './render/VueRender';

/**
 * Step1 初始化应用（可选）
 */
render({ loading: true });

const loader = loading => render({ loading });

/**
 * Step2 注册子应用
 */

const IP = "10.16.5.178";
registerMicroApps(
  [
    {
      name: 'system-users',
      entry: process.env.NODE_ENV === 'development' ? `//${IP}:4001/` : '/child/system-users/',
      container: '#subapp-viewport',
      // loader,
      activeRule: '/system-users',
    },
    {
      name: 'system-login',
      entry: process.env.NODE_ENV === 'development' ? `//${IP}:4002/` : '/child/system-login/',
      container: '#subapp-viewport',
      // loader,
      activeRule: '/system-login',
    },
    {
      name: 'system-company',
      entry: process.env.NODE_ENV === 'development' ? `//${IP}:4003/` : '/child/system-company/',
      container: '#subapp-viewport',
      // loader,
      activeRule: '/system-company',
    },
    {
      name: 'system-develop',
      entry: process.env.NODE_ENV === 'development' ? `//${IP}:4004/` : '/child/system-develop/',
      container: '#subapp-viewport',
      // loader,
      activeRule: '/system-develop',
    },
    {
      name: 'system-control',
      entry: process.env.NODE_ENV === 'development' ? `//${IP}:4005/` : '/child/system-control/',
      container: '#subapp-viewport',
      // loader,
      activeRule: '/system-control',
    },
    // {
    //   name: 'system-lpis',
    //   entry: process.env.NODE_ENV === 'development' ? `//${IP}:4006/` : '/child/system-lpis/',
    //   container: '#subapp-viewport',
    //   // loader,
    //   activeRule: '/system-lpis',
    // },
    {
      name: 'system-elevator',
      entry: process.env.NODE_ENV === 'development' ? `//${IP}:4006/` : '/child/system-elevator/',
      container: '#subapp-viewport',
      // loader,
      activeRule: '/system-elevator',
    },
    {
      name: 'system-coremanager',
      entry: process.env.NODE_ENV === 'development' ? `//${IP}:4007/` : '/child/system-coremanager/',
      container: '#subapp-viewport',
      // loader,
      activeRule: '/system-coremanager',
    },
    {
      name: 'system-protocol',
      entry: process.env.NODE_ENV === 'development' ? `//${IP}:4008/` : '/child/system-protocol/',
      container: '#subapp-viewport',
      // loader,
      activeRule: '/system-protocol',
    },
    {
      name: 'system-CPQ',
      entry: process.env.NODE_ENV === 'development' ? `//${IP}:4009/` : '/child/system-CPQ/',
      container: '#subapp-viewport',
      // loader,
      activeRule: '/system-CPQ',
    }
  ],
  {
    beforeLoad: [
      app => {
        console.log('[LifeCycle] before load %c%s', 'color: green;', app.name);
      },
    ],
    beforeMount: [
      app => {
        console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name);
      },
    ],
    afterUnmount: [
      app => {
        console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name);
      },
    ],
  },
);

const { onGlobalStateChange, setGlobalState } = initGlobalState({
  user: 'qiankun',
});

onGlobalStateChange((value, prev) => console.log('[onGlobalStateChange - master]:', value, prev));

setGlobalState({
  ignore: 'master',
  user: {
    name: 'master',
  },
});

/**
 * Step3 设置默认进入的子应用
 */
setDefaultMountApp('/system-login');

/**
 * Step4 启动应用
 */

start({
  prefetch: ['system-control', 'system-elevator'],
  excludeAssetFilter: (assetUrl) => {
    const whiteList = []; const whiteWords = ['baidu', 'bdimg', "map", "imouplayer", "googleapis", "module-imou"];
    if (whiteList.includes(assetUrl)) { return true }
    return whiteWords.some(w => { return assetUrl.includes(w) })
  }
});


// start()

runAfterFirstMounted(() => {
  console.log('[MainApp] first app mounted');
});
